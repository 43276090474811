var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-add-aftersale-upload-img" },
    [
      _c("div", { staticClass: "page-body" }, [
        _c(
          "div",
          { staticClass: "img-num-box box-item" },
          [
            _c("div", { staticClass: "img-num-box-title title-item" }, [
              _vm._v("异常探头总数")
            ]),
            _c("Field", {
              attrs: {
                placeholder: "请输入探头总数(小于1000)",
                "input-align": "left",
                max: "1000",
                type: "number"
              },
              model: {
                value: _vm.num,
                callback: function($$v) {
                  _vm.num = $$v
                },
                expression: "num"
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "upload-box box-item" }, [
          _c("div", { staticClass: "img-list-box-title title-item" }, [
            _vm._v("上传现场图片")
          ]),
          _c(
            "div",
            { staticClass: "img-list" },
            [
              _c("Uploader", {
                attrs: {
                  "max-size": 20000 * 200000,
                  "after-read": _vm.afterRead,
                  accept: "image/*",
                  "max-count": 9
                },
                model: {
                  value: _vm.fileList,
                  callback: function($$v) {
                    _vm.fileList = $$v
                  },
                  expression: "fileList"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "upload-box box-item" }, [
          _c(
            "div",
            {
              staticClass: "warning-title title-item",
              on: {
                click: function($event) {
                  _vm.isShow = true
                }
              }
            },
            [
              _vm._v("故障类型 "),
              _c("span", [_vm._v(_vm._s(_vm.tagItem.tagName || "请选择"))]),
              _c("Icon", { attrs: { name: "arrow-down" } })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "page-footer" }, [
        _c("div", { staticClass: "back-btn", on: { click: _vm.back } }, [
          _vm._v("返回")
        ]),
        _c(
          "div",
          {
            class: { "submit-btn": true, pass: _vm.isPass },
            on: { click: _vm.submit }
          },
          [_vm._v("提交")]
        )
      ]),
      _c("ActionSheet", {
        attrs: {
          actions: _vm.tagList,
          "cancel-text": "取消",
          "close-on-click-action": ""
        },
        on: { select: _vm.selectWarning },
        model: {
          value: _vm.isShow,
          callback: function($$v) {
            _vm.isShow = $$v
          },
          expression: "isShow"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }